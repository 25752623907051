<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("starting_order.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("starting_order.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t("title.title_sta") }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              :disabled="sending"
              v-if="
                showAction(1) && withPermissionName(permissions.ORD_GEN_ADD_04)
              "
              class="btn btn-outline-success"
              @click="changeAction(2)"
            >
              <i class="feather icon-plus mr-1"></i>
              {{ $t("starting_order.create_order") }}
            </button>
            <!--Modificación de boton se hizo de color verde-->
            <button
              v-if="showAction(2) || showAction(3)"
              class="btn btn-outline-success"
              @click="changeAction(1)"
            >
              <i class="feather icon-arrow-left mr-1"></i>
              {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>
      <!-- Start Contentbar -->
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title">
                      <i class="feather icon-file-text"></i>
                      {{ $t(titleAction) }}
                    </h5>
                  </div>
                </div>
              </div>

              <StartingOrderTableComponent
                v-if="showTable"
                :modelTable="listStartingOrder"
                :headers="decreaseListHeaders"
                :methodPage="listAllShipmentsByTypeDecrease"
                :pagesAtributes="pagesAtributes"
                :isUpdate="withPermissionName(permissions.ORD_GRN_EDIT_03)"
                :isDetail="withPermissionName(permissions.ORD_GEN_DETA_01)"
                :isDownloadPDF="
                  withPermissionName(permissions.ORD_GRN_DL_PDF_02)
                "
                :isButton="true"
                v-on:updateRecord="updateOrder"
                v-on:downloadPDF="downloadPDF"
                v-on:excelTable="excelTable"
                v-on:detail="detail"
                v-show="showAction(1)"
              />
              <div class="card-body" v-show="showAction(2)">
                <div class="row">
                  <div class="col-12">
                    <h6>
                      <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
                      {{ $t("starting_order.destination_branch") }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <AllBranchOfficeComponent
                      ref="branchOfficeDestination"
                      :branchLabel="$t('starting_order.destination_branch')"
                      v-on:branchChange="branchDestinationChange"
                    />
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <AllShipmentRequest
                      ref="allShipmentRequest"
                      v-on:branchChangeRequest="allShipmentRequestChange"
                      :alert="alert"
                    />
                  </div>
                  <div class="col-12 text-right" v-if="idOrder == ''">
                    <button
                      class="btn btn-outline-success "
                      @click="addShipmentTypeDecrease()"
                      :disabled="sending"
                    >
                      <i class="feather icon-plus mr-1"></i>
                      {{ $t("global.add_products") }}
                    </button>
                  </div>
                  <div class="col-12 text-right" v-else>
                    <button
                      class="btn btn-outline-success "
                      type="button"
                      @click="finishStartingOrder()"
                      :disabled="sending"
                    >
                      <i class="feather icon-check mr-1"></i>
                      {{ $t("global.finish_starting_order") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="showAction(2) && idOrder != ''">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <div class="row">
                <div class="col-md-12">
                  <ProductsTableComponent
                    ref="tableOrderStock"
                    :headers="orderHeaders"
                    :isDelete="true"
                    v-on:deleteRecord="deleteRecord"
                  />
                  <div class="card-body">
                    <StartingOrderFormComponent
                      ref="startingOrderFormComponent"
                      :getLotsByProduct="getLotsByProduct"
                      :getProductsByFiscalFound="getProductsByFiscalFound"
                      :getLots="getLots"
                      :modelStartingOrder="modelStartingOrder"
                      :addStockInOrder="addStockInOrder"
                      :getProductsBySupplier="getProductsBySupplier"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalOrderProductComponent ref="modalOrderDetail" />
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import {
  OrderStockRequest,
  StockRequest,
  ProductResquest,
  FiscalFoundRequest,
  LotRequest,
  ShipmentsRequest,
  ExcelRequest,
} from "@/core/request";
import { SwalDelete, SwalConfirm } from "@/core/SwalAlert";

import SelectBranchOffice from "@/common/select/SelectBranchOffice";
import AllBranchOfficeComponent from "@/common/select/AllBranchOfficeComponent";
import AllShipmentRequest from "@/common/select/AllShipmentRequest";

import StartingOrderTableComponent from "@/components/startingorder/StartingOrderTableComponent";
import ProductsTableComponent from "@/components/startingorder/ProductsTableComponent";
import StartingOrderFormComponent from "@/components/startingorder/StartingOrderFormComponent";

import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import ModalDeleteProductComponent from "@/common/modal/ModalDeleteProductComponent";
import ModalOrderProductComponent from "@/common/modal/ModalOrderProductComponent";
import ModalConfirmOrderComponent from "@/common/modal/ModalConfirmOrderComponent";
import {
  STARTING_ORDER,
  ORDER_DETAIL_ACTION,
} from "@/core/DataTableHeadersLocale";

// Vuex
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      title: "starting_order.starting_order",

      /* Cabeceras */
      decreaseListHeaders: STARTING_ORDER(),
      //orderProductsHeaders: DTHeaders.ORDER_PRODUCTS,
      orderHeaders: ORDER_DETAIL_ACTION(),

      /* General */
      action: 1,
      idUser: "",
      originIdBranchOffice: "",
      destinationId: "",
      idShimentRequest: "",
      oldDestinationId: "",
      loading: null,
      isUpdate: false,
      permissions: PermissionConstants,
      type: 7,

      /* Formulario */
      orderStockProducts: [],
      idOrder: "",

      modelStartingOrder: {
        productsList: [],
        lotList: [],
        fiscalFoundList: [],
        idFiscalFund: "",
        idProduct: "",
        idStock: "",
        quantity: "",
        supplierList: [],
        idSupplier: null,
        loadingProducts: false,
        loadingLot: false,
        loadingSupplier: false,
      },
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: true,
        sortFilters: {}
      },
      listStartingOrder: {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
      showTable: true,
      /* Cargas */
      sending: false,
      isExist: false,
      processing: false,
    };
  },
  computed: {
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    titleAction() {
      const titleMap = {
        1: "starting_order.starting_order",
        2: {
          "": "starting_order.create_order",
          existing: "starting_order.create_order_existing",
          new: "starting_order.update_order",
        },
      };
      const titleKey = this.idOrder ? (this.isExist ? "existing" : "new") : "";
      return titleMap[this.action][titleKey] || `starting_order.starting_order`;
    },
  },
  methods: {
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    /* General */
    showAction(action) {
      return this.action == action;
    },
    async changeAction(action) {
      if (action == 1) {
        this.action = action;
        this.clean(0);
      } else if (action == 2) {
        //Verifico si existe alguna merma pendiente
        this.isExist = false;
        this.orderExists();
        this.action = action;
      }
    },
    branchDestinationChange(destinationId) {
      this.destinationId = destinationId.idBranchOffice;
    },
    allShipmentRequestChange(idShimentRequest) {
      this.idShimentRequest = idShimentRequest;
    },
    modalDelete(item) {
      this.$refs.modalDeleteProduct.reloadComponent(item);
    },
    modalConfirmOrder() {
      this.$refs.modalConfirmOrder.reloadComponent();
    },
    async orderExists() {
      this.clean(1);
      //Verifio si existe algún orden de tipo salida que se encuentre pendiente de finalizar
      this.sending = true;
      this.loading = UtilFront.getLoadding();
      await ShipmentsRequest.validateShipmentAndOrder({
        type: 1,
        userBranchOffice: {
          idUser: this.idUser,
          idBranchOffice: this.branchOfficeState.idBranchoffice,
        },
      })
        .then(async (response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            //Guardo el id de la orden
            this.idOrder = data.idOrder;
            this.isExist = true;
            this.oldDestinationId = "";
            //Selecciono el destino del envio
            this.$refs.branchOfficeDestination.reloadComponentIdBranchOffice(
              data.destination
            );
            this.$refs.allShipmentRequest.reloadComponentClean();
            this.idShimentRequest = null;
            if (data.shipmentRequest != undefined) {
              this.$refs.allShipmentRequest.reloadComponentIdShimentRequest(
                data.shipmentRequest.idShipmentRequest
              );
              this.idShimentRequest = data.shipmentRequest.idShipmentRequest;
            }
            this.destinationId = data.destination;
            this.loading.hide();
            await this.orderDetail({
              idOrder: this.idOrder,
            });
            //Cambio a modo crear merma
            this.action = 2;
            this.updateAction(2);
          } else if (!success && message == "Crear nuevo envio.") {
            this.action = 2;
            this.updateAction(2);
          } else if (!success && message != "Crear nuevo envio.") {
            this.action = 1;
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
          this.loading.hide();
        });
    },
    updateOrder(item) {
      this.isExist = false;
      this.sending = true;
      //Guardo el id de la orden
      this.idOrder = item.orderId;
      this.$refs.branchOfficeDestination.reloadComponentIdBranchOffice(
        item.branchOfficeDestinationId
      );
      this.$refs.allShipmentRequest.reloadComponentClean();
      this.idShimentRequest = null;
      if (item.shipmentRequest != undefined) {
        this.$refs.allShipmentRequest.reloadComponentIdShimentRequest(
          item.idShipment
        );
        this.idShimentRequest = item.idShipment;
      }
      this.destinationId = item.branchOfficeDestinationId;
      this.oldDestinationId = item.branchOfficeDestinationId;
      this.isUpdate = true;
      this.loading.hide();
      this.orderDetail({
        idOrder: item.orderId,
      });
      this.sending = false;
      this.action = 2;
      this.updateAction(2);
    },
    //Se encarga de agregar el envio tipo salida
    async addShipmentTypeDecrease() {
      if (this.destinationId !== undefined) {
        this.sending = true;
        this.loading = UtilFront.getLoadding();
        //Deshabilito el select de sucursales de destino
        this.$refs.branchOfficeDestination.reloadComponentDisabled(true);
        await ShipmentsRequest.addShipmentWithOrderTypeStartingOrder({
          destination: this.destinationId,
          userBranchOffice: {
            idUser: this.idUser,
            idBranchOffice: this.branchOfficeState.idBranchoffice,
          },
          shipmentRequest: {
            idShipmentRequest:
              this.idShimentRequest == "" ? null : this.idShimentRequest,
          },
        })
          .then((response) => {
            let { success, message, data } = response.data.data;
            if (success) {
              this.loading.hide();
              //Almaceno el id de la orden generado ( Nuevo registro )
              this.idOrder = data.idOrder;
              //Cambio la accion llenar lista de productos
              this.action = 2;

              this.updateAction(2);
              this.alert("success", "Add_Product_0002");
            } else {
              this.alert("error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.alert("internal");
          })
          .finally(() => {
            this.sending = false;
            this.$refs.branchOfficeDestination.reloadComponentDisabled(false);
            this.loading.hide();
          });
      } else {
        this.alert("success", "Message_Branch_Destination_0001");
      }
    },
    getFiscalFund() {
      FiscalFoundRequest.listAllFiscalFundByBranchOfficeForType({
        type: this.type,
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOfficeState.idBranchoffice,
        },
      })
        .then((response) => {
          const { success, data } = response.data.data;
          if (success) {
            this.modelStartingOrder.fiscalFoundList = data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //Recupero los productos por fuente de financiamiento, sucursal y motivo de merma
    getProductsBySupplier() {
      this.clean(3);
      this.modelStartingOrder.loadingProducts = true;
      ProductResquest.findAllProductosByStockByType({
        shipmentsShippingReason: this.type,
        fiscalFundId: this.modelStartingOrder.idFiscalFund,
        typeModule: 1,
        typeWarehouse: 1,
        remissionRequisitionId: this.modelStartingOrder.idSupplier,
        idUser: this.idUser,
        idBranchOffice: this.branchOfficeState.idBranchoffice,
      })
        .then((response) => {
          const { success, data } = response.data.data;
          if (success) {
            this.modelStartingOrder.productsList = data;
            this.modelStartingOrder.loadingProducts = false;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.modelStartingOrder.loadingProducts = false;
        });
    },
    getProductsByFiscalFound() {
      this.clean(3);
      this.modelStartingOrder.loadingSupplier = true;
      if (this.modelStartingOrder.idFiscalFund == null) {
        this.modelStartingOrder.loadingSupplier = false;
        return;
      }
      this.getProductsBySupplier();
      ShipmentsRequest.listSupplierByFinancing({
        idFiscalFund: this.modelStartingOrder.idFiscalFund,
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOfficeState.idBranchoffice,
        },
      })
        .then((response) => {
          const { data } = response;
          if (data) {
            this.modelStartingOrder.loadingSupplier = false;
            this.modelStartingOrder.supplierList = data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.modelStartingOrder.loadingSupplier = false;
        });
    },
    getLotsByProduct() {
      this.clean(4);
      this.modelStartingOrder.loadingLot = true;
      if (this.modelStartingOrder.idProduct == null) {
        this.modelStartingOrder.loadingLot = false;
        return;
      }
      LotRequest.findAllLotsByType({
        shipmentsShippingReason: this.type,
        fiscalFundId: this.modelStartingOrder.idFiscalFund,
        typeModule: 1,
        typeWarehouse: 1,
        productId: this.modelStartingOrder.idProduct,
        remissionRequisitionId: this.modelStartingOrder.idSupplier,
        idUser: this.idUser,
        idBranchOffice: this.branchOfficeState.idBranchoffice,
      })
        .then((response) => {
          const { success, data } = response.data.data;
          if (success) {
            this.modelStartingOrder.lotList = data;
            this.modelStartingOrder.loadingLot = false;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.modelStartingOrder.loadingLot = false;
        });
    },
    getLots() {
      this.clean(5);
    },
    //Funcion que se encarga de agregar los productos a la orden
    async addStockInOrder() {
      if (this.modelStartingOrder.quantity !== "") {
        if (this.processing ) {
          // Si se está procesando una operación, no hacer nada
          return;
        }
        this.processing = true;
        this.loading = UtilFront.getLoadding();
        const requestData = {
          order: {
            idOrder: this.idOrder,
          },
          stock: {
            idStock: this.modelStartingOrder.idStock,
            quantity: this.modelStartingOrder.quantity,
          },
          userBranchOffice: {
            idUser: this.idUser,
            idBranchOffice: this.branchOfficeState.idBranchoffice,
          },
        };
        await StockRequest.updateStockWithOrderStock(requestData)
          .then(async (response) => {
            const { data } = response.data;
            const { success, message } = data;
            if (success) {
              // Show success message to user
              this.alert("success", "Add_Product_0001");
              this.loading.hide();
              await this.orderDetail({ idOrder: this.idOrder });
              this.getProductsByFiscalFound();
            } else {
              this.alert("error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.alert("warning", "Por el momento no se pudo atender la transacción, por favor intente de nuevo.");
          })
          .finally(() => {
            this.loading.hide();
            this.processing = false;
          });
      } else {
        this.alert("error", "Error_Product_Quantity_0001");
      }
    },
    /* RECARGAR LA LISTA DE PRODUCTOS AGREGADOS, MODIFICADOS Y ELIMINADOS */
    async orderDetail(item) {
      this.loading = UtilFront.getLoadding();
      await OrderStockRequest.orderStockDetail({
        idOrder: item.idOrder,
        status: [1, 4],
      })
        .then((response) => {
          let { status, data } = response;
          if (status == 200) {
            this.orderStockProducts = data;
            this.$refs.tableOrderStock.reloadComponent(data);
          } else if (status == 204) {
            this.alert("warning", message);
            this.$refs.tableOrderStock.reloadComponent([]);
          }
          this.loading.hide();
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async deleteRecord(item) {
      const { isConfirmed } = await SwalDelete.fire({
        title: this.$t("starting_order.title_delete"),
        html:
          '<div class="card">' +
          '<div class="card-body">' +
          '<div class="row">' +
          '<div class=" col-md-12"><b>' +
          "<i class='feather icon-info mr-2 text-fmedical-blue'></i>Fuente de Financiamiento: " +
          "</b>" +
          item.fiscalFoundKey +
          "</div>" +
          '<div class="col-md-6"><b>' +
          "<i class='feather icon-copy mr-2 text-fmedical-blue'></i>Cantidad: " +
          "</b>" +
          item.quantity +
          "</div>" +
          '<div class=" col-md-6"><b>' +
          "<i class='feather icon-grid mr-2 text-fmedical-blue'></i>Lote: " +
          "</b>" +
          item.lot +
          "</div>" +
          '<div class="col-md-12"><b>' +
          "<i class='feather icon-package mr-2 text-fmedical-blue'></i>Causes: " +
          "</b>" +
          item.productKey +
          "</div>" +
          '<div class="col-md-12"><b>' +
          "<i class='feather icon-calendar mr-2 text-fmedical-blue'></i>Fecha de caducidad: " +
          "</b>" +
          item.expirationDate +
          "</div>" +
          "<div class='col-md-12'><b>" +
          "<i class='feather icon-align-center mr-2 text-fmedical-blue'></i>Producto: " +
          "</b>" +
          item.productDescription +
          "</div>" +
          "</div>" +
          "</div>" +
          "</div>",
      });
      if (!isConfirmed) {
        return;
      }
      this.sending = true;
      //Mustro la pantalla de cargando
      this.loading = UtilFront.getLoadding();
      this.$refs.tableOrderStock.reloadComponentSending(true);
      OrderStockRequest.disableOrderStock({
        idOrderStock: item.idOrderStock,
        userId: this.idUser,
        branchOfficeId: this.branchOfficeState.idBranchoffice,
      })
        .then(async (response) => {
          let { status, data } = response;
          if (status == 200) {
            this.alert("success", "Success_Product_Delete_0001");
            this.loading.hide();
            await this.orderDetail({ idOrder: this.idOrder });
            //Recupero las fuente de financiamiento
            if (this.modelStartingOrder.idFiscalFund != "")
              this.getProductsByFiscalFound();
          } else if (status == 202) {
            this.alert("error", response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", "Error_Product_Delete_0001");
        })
        .finally(() => {
          //Habilito los botones finalizo el envio
          this.sending = false;
          this.$refs.tableOrderStock.reloadComponentSending(false);
          this.loading.hide();
          this.clean(1, true);
        });
    },
    clean(level, isAdd = false) {
      if (level == 0) {
        this.idOrder = "";
        this.$refs.allShipmentRequest.reloadComponentClean();
        this.idShimentRequest = null;
        this.$refs.branchOfficeDestination.reloadComponentClean();
        this.isUpdate = false;
      }
      if (level <= 1) {
        if (!isAdd) this.modelStartingOrder.idFiscalFund = "";
      }
      if (level <= 2) {
        //Se encarga de limpiar la lista de productos el item seleccionado
        this.modelStartingOrder.supplierList = [];
        this.modelStartingOrder.idSupplier = null;
      }
      if (level <= 3) {
        //Se encarga de limpiar la lista de lotes y el item seleccionado
        this.modelStartingOrder.idProduct = "";
        this.modelStartingOrder.productsList = [];
      }
      if (level <= 4) {
        //Se encarga de limpiar los campos de cantidad
        this.modelStartingOrder.lotList = [];
        this.modelStartingOrder.idStock = "";
      }
      if (level <= 5) {
        this.modelStartingOrder.quantity = "";
      }
      if (this.action == 2) {
        this.updateAction(2);
        this.$refs.startingOrderFormComponent.resetForm();
      }
    },
    //Esta funcion se encarga de actualizar la sucursal de destino y el motivo de la merma
    //En caso de ser modificacion de la merma, solo se actualiza el destino
    async finishStartingOrder() {
      if (this.destinationId > 0) {
        const { isConfirmed } = await SwalConfirm.fire({
          title: this.$t("decrease_order.title_modal_confirm"),
          text: this.$t("decrease_order.subtitle_modal_confirm"),
        });
        if (!isConfirmed) {
          return;
        }
        this.loading = UtilFront.getLoadding();
        //Deshabilito todos los botones hasta esperar que termino el envio
        this.sending = true;
        //Mustro la pantalla de cargando
        let data = {
          orderId: this.idOrder,
          destination: this.destinationId,
          userBranchOffice: {
            idUser: this.idUser,
            idBranchOffice: this.branchOfficeState.idBranchoffice,
          },
          shipmentRequest: {
            idShipmentRequest:
              this.idShimentRequest == "" ? null : this.idShimentRequest,
          },
        };
        ShipmentsRequest.finishStartingOrder(data)
          .then(async (response) => {
            let { success, message } = response.data.data;
            if (success) {
              //Mensajes
              this.alert("success", "Success_Send_0001");
              this.loading.hide();
              await this.listAllShipmentsByTypeDecrease(this.pagesAtributes);
              this.changeAction(1);
            } else {
              this.alert("error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.alert("internal");
          })
          .finally(() => {
            this.loading.hide();
            this.sending = false;
          });
      } else {
        this.alert("error", "Message_Branch_Destination_0001");
      }
    },
    //Recupero la lista de envios de tipo merma
    async listAllShipmentsByTypeDecrease(page) {
      this.loading = UtilFront.getLoadding();
      await ShipmentsRequest.listAllShipmentsByType({
        type: 1,
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.branchOfficeState.idBranchoffice,
        page,
      })
        .then((response) => {
          let { data } = response;
          this.listStartingOrder = data;
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    downloadPDF(item) {
      let send =
        "idOrder=" + item.orderId + "&" + "idShipment=" + item.idShipment;
      ExcelRequest.reportOrderPdf(send);
      this.$toast.success("Documento generado (documento.pdf)");
    },
    excelTable() {
      this.$toast.success("Reporte generado (documento.xlsx)");
      ExcelRequest.reportOrders({
        type: 1,
        userBranchOffice: {
          idUser: this.idUser,
          idBranchOffice: this.branchOfficeState.idBranchoffice,
        },
      });
    },
    detail(item) {
      this.$refs.modalOrderDetail.reloadComponent(item);
    },
  },
  created() {
    this.idUser = UtilFront.getDataUser().idUser;
    this.updateAction(1);
    this.isExist = false;
    this.getFiscalFund();
  },
  watch: {
    async branchOfficeState() {
      this.showTable = false;
      this.pagesAtributes = {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: false,
        sortFilters: {}
      };
      this.$nextTick(() => {
        this.showTable = true;
      });
      this.getFiscalFund();
    },
    action() {
      this.updateAction(this.action);
    },
    "$i18n.locale": function() {
      this.decreaseListHeaders = STARTING_ORDER();
      this.orderHeaders = ORDER_DETAIL_ACTION();
    },
    "modelStartingOrder.idFiscalFund": function() {
      this.modelStartingOrder.idSupplier = null;
    },
  },
  components: {
    SelectBranchOffice,
    AllBranchOfficeComponent,
    AlertMessageComponent,
    StartingOrderTableComponent,
    ProductsTableComponent,
    ModalDeleteProductComponent,
    ModalConfirmOrderComponent,
    ModalOrderProductComponent,
    AllShipmentRequest,
    StartingOrderFormComponent,
  },
};
</script>
